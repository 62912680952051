import React from "react";
import styled from "styled-components";
import noWeapon from "../assets/sem arma/01.jpg";
import noImg from "../assets/noimg.png";

const RankTable = styled.div`
  table {
    min-width: fit-content;
    width: 100%;
  }

  tr:nth-child(even) {
    background-color: #f1f1f1;
  }
  th {
    background-color: #eaeaea;
  }

  th,
  td {
    text-align: center;
    padding: 10px;
  }

  @media (min-width: 780px) {
    max-height: 70vh;
  }
`;

const Table = (props) => {
  const { data } = props;

  if (data) {
    //se a request já tiver sido feita
    console.log(data);
    try {
      if (data[0]["points"]) {
        //método de filtrar pelas royal
        for (let i = 0; i < data.length; i++) {
          delete data[i]["type"]; // apagando propriedade type
          // if(data[i]['isVIP'] === true){//substituindo booleanos por string
          //     data[i]['isVIP']= 'VIP'
          // }else if (data[i]['isVIP'] === false){
          //     data[i]['isVIP'] = ''
          // }
        }
      }

      if (data[0].name) {
        //filtrando pelo level
        for (let i = 0; i < data.length; i++) {
          delete data[i].points;
          delete data[i].class;
          delete data[i].subClass;
          // data[i].mount = "01";
          // data[i].weapon = "01";
          // data[i].skills = null;
        }
      }
    } catch (e) {
      console.error(e);
    }
  }
  if (!Array.isArray(data) || !data[0]) return <></>;
  return (
    <RankTable>
      <table>
        <thead>
          <tr>
            <th>Position</th>
            {Object.keys(data[0]).map((key) => {
              //preenchendo headers da tabela automaticamente
              // console.log(key)
              if (
                key === "position" ||
                key === "guild" ||
                key === "isVIP" ||
                key === "class" ||
                key === "subClass"
              ) {
                return;
              } //removendo o position dos royal

              if (key === "Soma Level") {
                return <th>Level Total</th>;
              }
              return <th key={key}>{key[0].toUpperCase() + key.slice(1)}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => {
            return (
              <tr key={index}>
                <td>{index + 1}º</td>
                {Object.keys(row).map((value) => {
                  //preenchendo corpo da tabela automaticamente
                  let fixLevel; // //gambiarra - ajeitar no back
                  if (
                    value === "position" ||
                    value === "isVIP" ||
                    value === "class" ||
                    value === "subClass"
                  ) {
                    return;
                  }

                  if (value === "guildMark") {
                    return (
                      <td>
                        <img
                          src={`https://wydmisc.raidhut.com.br/guild/img_guilds/global/${row[value]}.bmp`}
                          alt=""
                        />
                      </td>
                    );
                  }

                  if (value === "kingdom") {
                    return (
                      <td>
                        <img
                          src={`https://wydmisc.raidhut.com.br/guild/${row[value]}.gif`}
                          alt=""
                        />
                      </td>
                    );
                  }

                  if (value === "level" || value === "levelSub") {
                    //gambiarra - ajeitar no back
                    fixLevel = row[value] - 1;
                    return <td key={value}>{fixLevel}</td>;
                  }

                  if (value === "Soma Level") {
                    // gambiarra - ajeitar no back
                    fixLevel = row[value] - 2;
                    return <td key={value}>{fixLevel}</td>;
                  }

                  if (value === "guild") {
                    return;
                  }

                  /* if (value === "weapon"){
                                    return(<td><img src={`https://wydmisc.raidhut.com.br/guild/armaarch/${row[value] || noWeapon}.jpg`} alt=""/></td>)
                                }

                                if (value === "mount"){
                                    return(<td><img src={`https://wydmisc.raidhut.com.br/guild/montaria/${row[value] || '03'}.jpg`} alt=""/></td>)
                                }

                                if (value === "skills"){
                                    return(<td><img src={noWeapon} alt=""/></td>)
                                } */

                  return <td key={value}>{row[value]}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </RankTable>
  );
};

export default Table;
